/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as s } from "../chunks/tslib.es6.js";
import e from "./Accessor.js";
import { createResolver as r, createAbortError as i, isAbortError as t } from "./promiseUtils.js";
import { subclass as o } from "./accessorSupport/decorators/subclass.js";
var l;
!function (s) {
  s[s.PENDING = 0] = "PENDING", s[s.RESOLVED = 1] = "RESOLVED", s[s.REJECTED = 2] = "REJECTED";
}(l || (l = {}));
class h {
  constructor() {
    this._resolver = r(), this._status = l.PENDING, this._resolvingPromises = [], this._resolver.promise.then(() => {
      this._status = l.RESOLVED, this._cleanUp();
    }, () => {
      this._status = l.REJECTED, this._cleanUp();
    }), this.promise = this._resolver.promise;
  }
  destroy() {
    this._cleanUp();
  }
  addResolvingPromise(s) {
    this._resolvingPromises.push(s), this._tryResolve();
  }
  isResolved() {
    return this._status === l.RESOLVED;
  }
  isRejected() {
    return this._status === l.REJECTED;
  }
  isFulfilled() {
    return this._status !== l.PENDING;
  }
  abort() {
    this._resolver.reject(i());
  }
  _cleanUp() {
    this._allPromise = null, this._resolvingPromises = null;
  }
  _tryResolve() {
    if (this.isFulfilled()) return;
    const s = r(),
      e = [...this._resolvingPromises, s.promise],
      i = this._allPromise = Promise.all(e);
    i.then(() => {
      this.isFulfilled() || this._allPromise !== i || this._resolver.resolve();
    }, s => {
      this.isFulfilled() || this._allPromise !== i || t(s) || this._resolver.reject(s);
    }), s.resolve();
  }
}
const n = e => {
  let r = class extends e {
    constructor(...s) {
      super(...s), this._promiseProps = new h(), this.addResolvingPromise(Promise.resolve());
    }
    destroy() {
      this._promiseProps.destroy();
    }
    isResolved() {
      return this._promiseProps.isResolved();
    }
    isRejected() {
      return this._promiseProps.isRejected();
    }
    isFulfilled() {
      return this._promiseProps.isFulfilled();
    }
    when(s, e) {
      return this._promiseProps.promise.then(() => this).then(s, e);
    }
    catch(s) {
      return this.when(null, s);
    }
    addResolvingPromise(s) {
      s && !this._promiseProps.isFulfilled() && this._promiseProps.addResolvingPromise("_promiseProps" in s ? s.when() : s);
    }
  };
  return r = s([o("esri.core.Promise")], r), r;
};
let m = class extends n(e) {};
m = s([o("esri.core.Promise")], m);
export { m as EsriPromise, n as EsriPromiseMixin };